<template>
  <div class="app">

    <el-card class="box-card">
      <el-tabs v-model="activeName"  @tab-click="tabsBtn">

        <el-tab-pane label="基本設定" name="bookingRules">
          <h1>配置</h1>
          <el-row :gutter="24">
            <el-form :model="bookingRulesForm" :rules="rulesAll.bookingRulesForm" ref="bookingRulesForm" label-width="100px">


              <el-col :span="12">
                <el-form-item label="年龄限制" prop="ageRegular">
                  <el-input v-model="bookingRulesForm.ageRegular" placeholder="填寫年龄限制正则">
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="手提限制" prop="phoneLimitRegular">
                  <el-input v-model="bookingRulesForm.phoneLimitRegular" placeholder="填寫手提號碼限制正则">
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24"><h1>時間設定</h1></el-col>

              <el-col :span="12">
                <el-form-item label="開啟時間" prop="pickerStart">
                  <el-input v-model="bookingRulesForm.pickerStart" placeholder="填寫開啟時間，如：11:30">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="結束時間" prop="pickerEnd">
                  <el-input v-model="bookingRulesForm.pickerEnd" placeholder="填寫結束時間，如：19:00">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="梯級時間" prop="pickerStep">
                  <el-input v-model="bookingRulesForm.pickerStep" placeholder="填寫梯級時間，如：00:30">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="預約限制" prop="pickerHoursLimit">
                  <el-input v-model="bookingRulesForm.pickerHoursLimit" placeholder="填寫梯級時間內容可預約人數，如：2">
                    <template slot="append">位顧客</template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="約滿警告" prop="pickerStepLimitWarning">
                  <el-input
                    v-model="bookingRulesForm.pickerStepLimitWarning"
                    type="textarea"
                    :rows="2">
                  </el-input>
                </el-form-item>
              </el-col>


              <el-col :span="24">
                <el-form-item label="時間規則表" prop="pickerTimeRuleTable">
                  <div class="pre" v-html="syntaxHighlight(bookingRulesForm.pickerTimeRuleTable)"></div>
                </el-form-item>
              </el-col>



              <el-col :span="24" style="text-align: right;">
                <el-form-item>
                  <el-button @click="resetForm('bookingRulesForm')">重置</el-button>
                  <el-button
                    type="primary"
                    :loading="bookingRulesFormLoading"
                    @click="submitForm('bookingRulesForm')">保存配置</el-button>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="Call Team試做規則" name="callTeamTryRules">
          <h1>配置</h1>
          <el-row :gutter="24">

            <el-form :model="callTeamTryRulesForm" :rules="rulesAll.callTeamTryRulesForm" ref="callTeamTryRulesForm" label-width="100px">

              <el-col :span="12">
                <el-form-item label="預約類型" prop="bookingType">
                  <el-select v-model="callTeamTryRulesForm.bookingType" disabled placeholder="請選擇" style="width: 100%;">
                    <el-option
                      v-for="item in bookingTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="預約保護期" prop="protection">
                  <el-input v-model="callTeamTryRulesForm.protection" placeholder="填寫預約保護期">
                    <template slot="append">天內</template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="預約次數" prop="frequency">
                  <el-input v-model="callTeamTryRulesForm.frequency" placeholder="填寫預約次數">
                    <template slot="append">次</template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item :label="`再次約`" prop="againAbout">
                  <el-select v-model="callTeamTryRulesForm.againAbout" placeholder="請選擇" style="width: 100%;">
                    <el-option
                      v-for="item in againAboutList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="试做條件" prop="storeTrialConditions">
                  <el-select v-model="callTeamTryRulesForm.storeTrialConditions" placeholder="請選擇" style="width: 100%;">
                    <el-option
                      v-for="item in storeTrialConditionsList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>


              <el-col :span="24"><h1> 警告提示</h1></el-col>

              <el-col :span="24">
                <el-form-item label="預約保護期" prop="protectionWarning">
                  <el-input
                    v-model="callTeamTryRulesForm.protectionWarning"
                    type="textarea"
                    :rows="2">
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item :label="`再次約`" prop="againAboutWarning">
                  <el-input
                    v-model="callTeamTryRulesForm.againAboutWarning"
                    type="textarea"
                    :rows="2">
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="试做條件" prop="storeTrialConditionsWarning">
                  <el-input
                    v-model="callTeamTryRulesForm.storeTrialConditionsWarning"
                    type="textarea"
                    :rows="2">
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24" style="text-align: right;">
                <el-form-item>
                  <el-button @click="resetForm('callTeamTryRulesForm')">重置</el-button>
                  <el-button
                    type="primary"
                    :loading="callTeamTryRulesFormLoading"
                    @click="submitForm('callTeamTryRulesForm')">保存配置</el-button>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="Road Show試做規則" name="roadShowTryRules">
          <h1>配置</h1>
          <el-row :gutter="24">
            <el-form :model="roadShowTryRulesForm" :rules="rulesAll.roadShowTryRulesForm" ref="roadShowTryRulesForm" label-width="100px">
              <el-col :span="12">
                <el-form-item label="預約類型" prop="bookingType">
                  <el-select v-model="roadShowTryRulesForm.bookingType" disabled placeholder="請選擇" style="width: 100%;">
                    <el-option
                      v-for="item in bookingTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="預約保護期" prop="protection">
                  <el-input v-model="roadShowTryRulesForm.protection" placeholder="填寫預約保護期">
                    <template slot="append">天內</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="預約次數" prop="frequency">
                  <el-input v-model="roadShowTryRulesForm.frequency" placeholder="填寫預約次數">
                    <template slot="append">次</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="`已滿${roadShowTryRulesForm.frequency||0}次再約`" prop="againAbout">
                  <el-select v-model="roadShowTryRulesForm.againAbout" placeholder="請選擇" style="width: 100%;">
                    <el-option
                      v-for="item in againAboutList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="试做條件" prop="storeTrialConditions">
                  <el-select v-model="roadShowTryRulesForm.storeTrialConditions" placeholder="請選擇" style="width: 100%;">
                    <el-option
                      v-for="item in storeTrialConditionsList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="24"><h1> 警告提示</h1></el-col>

              <el-col :span="24">
                <el-form-item label="預約保護期" prop="protectionWarning">
                  <el-input
                    v-model="roadShowTryRulesForm.protectionWarning"
                    type="textarea"
                    :rows="2">
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item :label="`已滿${roadShowTryRulesForm.frequency||0}次再約`" prop="againAboutWarning">
                  <el-input
                    v-model="roadShowTryRulesForm.againAboutWarning"
                    type="textarea"
                    :rows="2">
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="试做條件" prop="storeTrialConditionsWarning">
                  <el-input
                    v-model="roadShowTryRulesForm.storeTrialConditionsWarning"
                    type="textarea"
                    :rows="2">
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24" style="text-align: right;">
                <el-form-item>
                  <el-button @click="resetForm('roadShowTryRulesForm')">重置</el-button>
                  <el-button
                    type="primary"
                    :loading="roadShowTryRulesFormLoading"
                    @click="submitForm('roadShowTryRulesForm')">保存配置</el-button>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="門店設定" name="storesSet">


          <div v-if="storesSetAddOrEdit">
            <div style="margin-bottom: 13px;">
              <el-button type="warning" size="small" @click="storesSetAddOrEdit =! storesSetAddOrEdit">
                <i class="fa fa-reply"></i> 返回
              </el-button>
            </div>
            <h1>{{storesSetTitle}}門店</h1>
            <el-form :model="storesSetForm" :rules="rulesAll.storesSetForm" ref="storesSetForm" label-width="100px">
              <el-col :span="12">
                <el-form-item label="門店名稱" prop="title">
                  <el-input v-model="storesSetForm.title" placeholder="填寫門店名稱"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="門店熱線">
                  <el-input v-model="storesSetForm.hotline" placeholder="填寫門店熱線"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="展示狀態">
                  <el-switch
                    v-model="storesSetForm.status"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="門店地址">
                  <el-input v-model="storesSetForm.address" placeholder="填寫門店地址" type="textarea" :rows="3"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="備註">
                  <el-input v-model="storesSetForm.remark" placeholder="填寫備註" type="textarea" :rows="3" />
                </el-form-item>
              </el-col>

              <el-col :span="24" style="text-align: right;">
                <el-form-item>
                  <el-button @click="resetForm('storesSetForm')">重置</el-button>
                  <el-button
                    type="primary"
                    :loading="storesSetFormLoading"
                    @click="submitForm('storesSetForm')">確認</el-button>
                </el-form-item>
              </el-col>
            </el-form>
          </div>



          <div v-else>
            <div style="margin-bottom: 13px;">
              <el-row>
              <el-col :span="12">
                <el-button type="success" size="small" @click="addOrEdit()">
                  <i class="el-icon-plus" style="font-weight: 900;"></i> 添加門店
                </el-button>
              </el-col>
              <el-col :span="12">
                <el-input placeholder="查詢門店" size="small" v-model="query" class="employees-input">
                  <el-button slot="append" @click="queryBin">
                    <i class="fa fa-search"></i>
                  </el-button>
                </el-input>
              </el-col>
              </el-row>
            </div>
            <el-table
              class="lists"
              ref="RoomTable"
              :data="data.list"
              :height="data.total?clientHeight:'100'"
              v-loading="loading"
              border
              style="width: 100%;">
              <el-table-column fixed prop="id" min-width="60" label="編號"></el-table-column>
              <el-table-column fixed prop="title" min-width="90" label="門店名稱"></el-table-column>
              <el-table-column prop="hotline" min-width="100" label="門店熱線"></el-table-column>
              <el-table-column prop="address" label="門店地址" width="160"></el-table-column>
              <el-table-column label="展示狀態" width="100">
                <template slot-scope="scope">
                  <div style="text-align: center;">
                    <el-switch
                      v-model="scope.row.status?true:false"
                      active-color="#13ce66"
                      inactive-color="#ff4949" @change="storeStatusBtn(scope.row.id,scope.row.status)">
                    </el-switch>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="creationTime" label="添加時間" width="130"></el-table-column>
              <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                  <div style="text-align: center;">
                    <el-link type="primary" size="mini" @click="addOrEdit(scope.row)">
                      編輯
                    </el-link> |
                    <el-link type="danger" size="mini" @click="del(scope.row.id)">
                      刪除
                    </el-link>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <div class="pager">
              <el-pagination
                background
                layout="prev, pager, next"
                @current-change="pageBin"
                :page-size="data.pageSize"
                :total="data.total"
              ></el-pagination>
            </div>
          </div>


        </el-tab-pane>
      </el-tabs>
    </el-card>

  </div>
</template>

<script>
    export default {
        data () {
            return {
              activeName: 'bookingRules',
              clientHeight: '500',
              bookingRulesFormLoading:false,
              callTeamTryRulesFormLoading:false,
              roadShowTryRulesFormLoading:false,
              storesSetFormLoading:false,
              loading:false,

              // 預約規則
              bookingRulesForm:{
                ageRegular: '',
                phoneLimitRegular: '',
                pickerStart:'',
                pickerStep:'',
                pickerEnd:'',
                pickerHoursLimit:'',
                pickerStepLimitWarning:'',
                pickerTimeRuleTable:'',
              },

              // callTeam試做規則
              callTeamTryRulesForm:{
                bookingType: '',
                protection: '',
                frequency: '',
                againAbout: '',
                storeTrialConditions: '',
              },
              // roadShow試做規則
              roadShowTryRulesForm:{
                bookingType: '',
                protection: '',
                frequency: '',
                againAbout: '',
                storeTrialConditions: '',
              },
              bookingTypeList: [
                {
                  value: '0',
                  label: '普通顧客'
                },
                {
                  value: '1',
                  label: '會員'
                }
              ],
              againAboutList: [
                {
                  value: '0',
                  label: '半年後可再預約'
                },
                {
                  value: '1',
                  label: '1年後可再預約'
                }
              ],
              storeTrialConditionsList: [
                {
                  value: '0',
                  label: '第1~2次可同一門店，第3次不能預約相同門店'
                },
                {
                  value: '1',
                  label: '第1~3次可同一門店'
                },
                {
                  value: '2',
                  label: '第1~3次不能預約相同門店'
                }
              ],

              // 門店設定
              storesSetAddOrEdit:false,
              storesSetTitle: '添加',
              storesSetForm: {
                id: 0,
                title: '',
                hotline: '',
                address: '',
                status: false,
                remark: '',
              },
              query: '',
              data:{
                pageSize: 20,
                total: 0,
                list: [],
              },
              page: 1,

              rulesAll:{
                bookingRulesForm: {
                  ageRegular: [
                    { required: true, message: '不能留空。', trigger: 'blur' }
                  ],
                  phoneLimitRegular: [
                    { required: true, message: '手提限制不能留空。', trigger: 'blur' }
                  ],
                  pickerStart: [
                    {
                      type: 'string',
                      required: true,
                      message: '請填寫時間。',
                      trigger: 'blur'
                    },
                    {
                      type: 'string',
                      required: true,
                      pattern: /:/,
                      message: '時間格式錯誤。',
                    }
                  ],
                  pickerStep: [
                    {
                      type: 'string',
                      required: true,
                      message: '請填寫時間。',
                      trigger: 'blur'
                    },
                    {
                      type: 'string',
                      required: true,
                      pattern: /:/,
                      message: '時間格式錯誤。',
                    }
                  ],
                  pickerEnd: [
                    {
                      type: 'string',
                      required: true,
                      message: '請填寫時間。',
                      trigger: 'blur'
                    },
                    {
                      type: 'string',
                      required: true,
                      pattern: /:/,
                      message: '時間格式錯誤。',
                    }
                  ],
                  pickerHoursLimit: [
                    {
                      type: 'string',
                      required: true,
                      message: '請填寫預約限制。',
                      trigger: 'blur'
                    },
                  ],
                  pickerStepLimitWarning: [
                    {
                      type: 'string',
                      required: true,
                      message: '請填寫約滿警告。',
                      trigger: 'blur'
                    },
                  ]
                },

                callTeamTryRulesForm: {
                  bookingType: [
                    { required: true, message: '請選擇預約類型。', trigger: 'blur' }
                  ],
                  protection: [
                    { required: true, message: '預約保護期不能留空。', trigger: 'blur' }
                  ],
                  frequency: [
                    { required: true, message: '預約次數不能留空。', trigger: 'blur' }
                  ],
                  againAbout: [
                    { required: true, message: '請選擇已滿再次預約。', trigger: 'blur' }
                  ],
                  storeTrialConditions: [
                    { required: true, message: '請選擇試做條件。', trigger: 'blur' }
                  ],
                  protectionWarning: [
                    { required: true, message: '不能留空。', trigger: 'blur' }
                  ],
                  againAboutWarning: [
                    { required: true, message: '不能留空。', trigger: 'blur' }
                  ],
                  againAboutWarningWarning: [
                    { required: true, message: '不能留空。', trigger: 'blur' }
                  ],
                },
                roadShowTryRulesForm: {
                  bookingType: [
                    { required: true, message: '請選擇預約類型。', trigger: 'blur' }
                  ],
                  protection: [
                    { required: true, message: '預約保護期不能留空。', trigger: 'blur' }
                  ],
                  frequency: [
                    { required: true, message: '預約次數不能留空。', trigger: 'blur' }
                  ],
                  againAbout: [
                    { required: true, message: '請選擇已滿再次預約。', trigger: 'blur' }
                  ],
                  storeTrialConditions: [
                    { required: true, message: '請選擇試做條件。', trigger: 'blur' }
                  ],
                  protectionWarning: [
                    { required: true, message: '不能留空。', trigger: 'blur' }
                  ],
                  againAboutWarning: [
                    { required: true, message: '不能留空。', trigger: 'blur' }
                  ],
                  againAboutWarningWarning: [
                    { required: true, message: '不能留空。', trigger: 'blur' }
                  ],
                },
                storesSetForm: {
                  title: [
                    { required: true, message: '請填寫門店名稱。', trigger: 'blur' }
                  ],
                },
              },

            }
        },
      mounted() {
        let that = this
        that.getAllInfo()
      },
      methods: {
        storeStatusBtn(id,status){
          let that = this
          let msg = "您確認要展示門市嗎?"
          let statusInt = 1
          if(status){
            msg = "您確認要隱藏門市嗎?"
            statusInt = 0
          }
          that.$confirm(msg, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            that.loading = true
            that.$http.PUT("/v1/booking/setStoreStatus?", {id:id,status:statusInt}, function (ret) {
                that.loading = false
                if (ret.errcode == 0) {
                  that.$notify({
                    title: "成功",
                    message: ret.errmsg,
                    type: "success",
                  });
                  that.getStoreList(that.page)
                } else if (ret.errcode == 1) {
                  that.$notify.error({
                    title: "错误",
                    message: ret.errmsg,
                  });
                }
              },
              function (err) {
                that.loading = false
                that.$notify({
                  title: "警告",
                  message: err,
                  type: "warning",
                });
              }
            );
          }).catch(() => {
            that.$notify({
              title: "提示",
              message: "已取消",
              type: "warning",
            });
          });
        },
        // 導航切換按鈕
        tabsBtn(tab) {
          let that = this
          switch (tab.name) {
            case "storesSet":
              that.getStoreList()
              break;
          }
        },
        // 獲取配置信息
        getAllInfo(){
          let that = this
          that.$http.GET('/v1/booking/getConfigInfo',function(ret){
            if(ret.errcode == 0){
              that.bookingRulesForm = ret.data.bookingRulesForm || {}

              that.callTeamTryRulesForm =  ret.data.callTeamTryRulesForm || {}
              that.roadShowTryRulesForm =  ret.data.roadShowTryRulesForm || {}
            }
          },function(err){
            that.$notify({
              title: '警告',
              message: err,
              type: 'warning'
            });
          })
        },
        // 获取門店列表
        getStoreList(page) {
          let that = this;
          let param = that.$atk.json_to_url({
            page: page || parseInt(that.page),
            query: that.query,
          });
          that.loading = true
          this.$http.GET(
            "/v1/booking/getStoreList?" + param,
            function (ret) {
              that.loading = false
              if (ret.errcode == 0) {
                //that.data = ret.data;
                for(let i in ret.data.list){
                  ret.data.list[i].status = ret.data.list[i].status?true:false
                }
                that.data = ret.data;

              } else if (ret.errcode >= 1) {
                that.$message({
                  message: ret.errmsg,
                  type: "warning",
                });
              }
            },
            function (err) {
              that.loading = false
              that.$notify({
                title: "警告",
                message: err,
                type: "warning",
              });
            }
          );
        },
        // 分页
        pageBin(val) {
          this.page = val;
          this.getStoreList(val);
        },
        // 查询
        queryBin() {
          let that = this;
          if (!that.query) {
            that.$message({
              message: "请输入查询内容",
              type: "warning",
            });
            return;
          }
          this.getStoreList();
        },
        // 添加编辑
        addOrEdit(item){
          this.storesSetAddOrEdit = true
          if(item) {
            this.storesSetTitle = '編輯'
            this.storesSetForm ={
              ...item
            }
          } else {
            this.resetStoresSetForm()
          }
        },
        //删除
        del(id){
          let that = this;
          let param = that.$atk.json_to_url({
            id: id,
          });

          if(id <= 6){
            that.$notify.error({
              title: "错误",
              message: "系統內設門店禁止刪除，請聯繫管理員。",
            });
            return
          }

          that.$confirm("您确认要删除吗?", "删除提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            that.loading = true
            that.$http.DELETE("/v1/booking/delStore?" + param, function (ret) {
                that.loading = false
                if (ret.errcode == 0) {
                  that.$notify({
                    title: "成功",
                    message: ret.errmsg,
                    type: "success",
                  });
                  that.deleItem(id);
                } else if (ret.errcode == 1) {
                  that.$notify.error({
                    title: "错误",
                    message: ret.errmsg,
                  });
                }
              },
              function (err) {
                that.loading = false
                that.$notify({
                  title: "警告",
                  message: err,
                  type: "warning",
                });
              }
            );
          }).catch(() => {
              that.$notify({
                title: "回收提示",
                message: "已取消回收用户",
                type: "warning",
              });
          });
        },
        // 删除列表
        deleItem(id) {
          let list = this.data.list
          for (let key in this.data.list) {
            if (list[key].id === id) {
              list.splice(key, 1);
            }
          }
        },

        // 提交
        submitForm(formName) {
          let that = this,url='',data={}
          switch (formName) {
            case "bookingRulesForm":
              url = '/v1/booking/setBookingRules'
              let timeList = that.$atk.timeDifference(
                that.bookingRulesForm.pickerStart,
                that.bookingRulesForm.pickerStep,
                that.bookingRulesForm.pickerEnd
              )
              let timeRuleTable=[]
              for(let i in timeList){
                timeRuleTable.push({
                  time:timeList[i],
                  count:parseInt(that.bookingRulesForm.pickerHoursLimit)
                })
              }
              that.bookingRulesForm.pickerTimeRuleTable = JSON.stringify(timeRuleTable);
              data = {
                ...that.bookingRulesForm
              }
              that.bookingRulesFormLoading = true
              break;
            case "callTeamTryRulesForm":
              url = '/v1/booking/setCallTeamTryRules'
              data = {
                ...that.callTeamTryRulesForm
              }
              that.callTeamTryRulesFormLoading = true
              break;
            case "roadShowTryRulesForm":
              url = '/v1/booking/setRoadShowTryRules'
              data = {
                ...that.roadShowTryRulesForm
              }
              that.roadShowTryRulesFormLoading = true
              break;
            case "storesSetForm":
              url = '/v1/booking/addOrEditStore'
              data = {
                ...that.storesSetForm,
                status:that.storesSetForm.status?1:0
              }
              that.storesSetFormLoading = true
              break;
          }
          that.$refs[formName].validate((valid) => {
            if (valid) {
              that.$http.PUT(url,data,function(ret){
                that.bookingRulesFormLoading  = false
                that.callTeamTryRulesFormLoading      = false
                that.roadShowTryRulesFormLoading = false
                that.storesSetFormLoading     = false
                if(ret.errcode == 0){
                  that.$notify({
                    title: '成功',
                    message: ret.errmsg,
                    type: 'success'
                  });
                  if(formName=="storesSetForm"){
                    that.getStoreList();
                    if(data.id <= 0){
                      that.resetStoresSetForm();
                    }
                  }
                }else if(ret.errcode >= 1){
                  that.$notify.error({
                    title: '失败',
                    message: ret.errmsg
                  });
                }
              },function(err){
                that.bookingRulesFormLoading  = false
                that.callTeamTryRulesFormLoading = false
                that.roadShowTryRulesFormLoading = false
                that.storesSetFormLoading     = false
                that.$notify({
                  title: '警告',
                  message: err,
                  type: 'warning'
                });
              })
            } else {
              return false;
            }
          });
        },
        // 重置
        resetForm(formName) {
          this.$refs[formName].resetFields();
        },
        // 重置
        resetStoresSetForm() {
          this.storesSetForm = {
            id: 0,
            title: '',
            hotline: '',
            address: '',
            status: false,
            remark: '',
          }
        },
        syntaxHighlight(json) {
          if (typeof json != 'string') {
            json = JSON.stringify(json, undefined, 2);
          }
          json = json.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>');
          return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
            let cls = 'number';
            if (/^"/.test(match)) {
              if (/:$/.test(match)) {
                cls = 'key';
              } else {
                cls = 'string';
              }
            } else if (/true|false/.test(match)) {
              cls = 'boolean';
            } else if (/null/.test(match)) {
              cls = 'null';
            }
            return '<span class="' + cls + '">' + match + '</span>';
          });
        },
      },
    }
</script>
<style scoped lang="scss">
  .copyBtn{
    padding-top: 10px;
    color: $primary;
    text-decoration: none;
    transition: all 0.5s;
    cursor: pointer;
    &:hover {
      color: #5daf34;
    }
  }
  .formTemplate{
    display: block;
    resize: vertical;
    padding: 5px 15px;
    line-height: 1.5;
    box-sizing: border-box;
    width: 100%;
    height: 200px;
    font-size: inherit;
    color: #606266;
    background-color: #fff;
    background-image: none;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    &:focus {
      outline: none;
      border-color: #409eff;
    }
  }
</style>
<style>
  .pre{
    min-height: 60px;
    max-height: 300px;
    word-wrap: normal;
    word-break: break-all;
    white-space: pre;
    overflow-x: scroll;
    overscroll-behavior-x: contain;
    margin-top: 0;
    margin-bottom: 20px;
    border-radius: 4px;
    z-index: 0;
    padding: 1em;
    padding-left: 1em;
    line-height: 1.5;
    color: #ccc;
    background: #2d2d2d;
  }
  .string { color: green; }
  .number { color: darkorange; }
  .boolean { color: blue; }
  .null { color: magenta; }
  .key { color: red; }
</style>
